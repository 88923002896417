import React, { useState, useEffect } from "react";
import NavBar from "./componentes/NavBar";
import ItemCupon from "./componentes/ItemCupon";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate , Link} from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "./FirebaseConfig/firebase";
import "./CSS/cupones.css";
const MySwal = withReactContent(Swal);

function Cupones() {
  const navigate = useNavigate();
  //configurar useState
  const [cupones, setCupones] = useState([]);

  //referenciar la DB
  const cuponesList = collection(db, "CUPONES");
  //funcion para mostrar la lista de cupones
  const getCupones = async () => {
    const data = await getDocs(cuponesList);
    setCupones(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log(cupones);
  };
  //Funcion para eliminar cupon
  const deleteCupon = async (id) => {
    const cupon = doc(db, "CUPONES", id);
    await deleteDoc(cupon);
    getCupones();
  };

  //Alert eliminar cupon
  const confirmDelete = (id) => {
    console.log("el id es = " + id);
    MySwal.fire({
      title: "Eliminar cupon?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar! ",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCupon(id);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'El cupón ha sido eliminado',
          showConfirmButton: false,
          timer: 1000
        })
      }
    });
  };
  //cerrar cupones
  const cerrar = () => {
    navigate("../panel");
  };
  //uso de useEffect
  useEffect(() => {
    getCupones();
  }, []);

  return (
    <div className="cupones">
      <NavBar
        titulo="Cupones"
        imagen="cerrar"
        logoTitulo="cupon"
        cerrar={cerrar}
      ></NavBar>
      <div class="cupones-grid-container">
        {cupones.map((item) => (
          <div className="cupones-element">
            <ItemCupon
              tienda={item.tienda}
              descripcion={item.descripcion}
              id={item.id}
              deleteCupon={confirmDelete}
              imagen={item.urlImagen}
            />
          </div>
        ))}
      </div>

      <Link className="cupones-flotante" to={`/panel/cupon/agregarCupon`}>
        <img
          src={require(`./img/plus.png`)}
          className="cupones-boton"
          border="0"
        />
      </Link>
    </div>
  );
}
export default Cupones;
