import "../CSS/formAgregarComunicado.css";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig/firebase";
import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { async } from "@firebase/util";
import NavBar from "./NavBar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LinearProgress from "@mui/material/LinearProgress";
import QuillEditor from "./QuillEditor";
const MySwal = withReactContent(Swal);

function Notificaciones() {
  const navigate = useNavigate();

  //Configurar hooks
  const [titulo, setTitulo] = useState("");
  const [detalle, setDetalle] = useState("");
  const [mensaje, setMensaje] = useState("");
  const comunicadosList = collection(db, "COMUNICADOS");
  //Enviar notificaciones
  const enviarSolicitud = () => {
    const url = "https://fcm.googleapis.com/fcm/send "; // Reemplaza con la URL de tu API
    // Datos que deseas enviar en el cuerpo de la solicitud POST
    const datos = {
		"notification": {
			"title": titulo,
			"body": detalle,
			"icon": "../img/notificacion.png"
		},
		"to": "/topics/all" 
	}
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Especifica el tipo de contenido en el cuerpo de la solicitud
        Authorization:
          "key=AAAANStXOFY:APA91bFoKpgbGz_GYpj-TaynD6fMBuDXkMoinnCbn5lmdyguxPut1phAicar8mxn4QEgT-4QvnGQew2reohSYpSIVVVr3mjrntB4UbTyBuf1ABpEt7r_wgkuCjU-yQSwyxyhsSenJ2Er",
      },
      body: JSON.stringify(datos), // Convierte el objeto de datos a formato JSON
    })
      .then((response) => response.json()) // Parsea la respuesta como JSON
      .then((data) => {
        // Aquí puedes manejar la respuesta del servidor
        setMensaje(data.mensaje); // Por ejemplo, mostrar un mensaje de éxito o error
      })
      .catch((error) => {
        // Aquí puedes manejar cualquier error ocurrido durante la solicitud
        console.error("Error:", error);
      });
  };

  const validarFormulario = (e) => {
    if (titulo == "" || detalle == "" ) {
      MySwal.fire("", "Llenar todos los campos", "error");
    } else {
      store(e);
    }
  };
  const store = async (e) => {
    MySwal.fire({
      title: "Enviar Notificación? ",
      text: "Esta notificación se mostrara a todos los usuarios",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        enviarSolicitud(titulo);
       
        Swal.fire("Enviado", "La notificacion ha sido enviada", "success");
        navigate("/panel/");
      }
    });
  };
  
  //cerrar cupones
  const cerrar = () => {
    navigate("/panel/");
  };
  return (
    <Fragment>
      <NavBar
        titulo="Enviar Notificación"
        nombre={""}
        imagen="cerrar"
        logoTitulo="notificacion"
        cerrar={cerrar}
      ></NavBar>
      <div className="agregarComunicado">
        <div className="fac-grid-container">
          <div className="fac-grid-item">
            <form action="/" className="fac-form">
              <label className="fac-label">Titulo</label>
              <input
                type="text"
                id="titulo"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className="fac-input fac-input-titulo"
              />
              <label className="fac-label">Detalles</label>
              <input
                type="text"
                id="detalle"
                value={detalle}
                onChange={(e) => setDetalle(e.target.value)}
                className="fac-input fac-input-titulo"
              />
            </form>
          </div>
          <div className="fac-grid-item">
            <div>
              <input
                type="submit"
                onClick={validarFormulario}
                value="Enviar Notificación"
                className="fac-btnAgregarComunicado"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Notificaciones;
