import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Routes, HashRouter, BrowserRouter, Route } from "react-router-dom";
import Login from "./Login";
import Panel from "./Panel";
import Cupones from "./Cupones";
import FormAgregarComunicado from "./componentes/FormAgregarComunicado";
import DetallesComunicado from "./componentes/DetallesComunicado";
import AgregarCupon from "./componentes/FormAgregarCupon";
import FormEditarCupon from "./componentes/FormEditarCupon";
import Usuarios from "./Usuarios";
import FormAgregarUsuario from "./componentes/FormAgregarUsuario";
import FormEditarUsuario from "./componentes/FormEditarUsuario";
import MainTrabajador from "./MainTrabajador";
import DetallesCuponTrabajador from "./componentes/DetallesCuponTrabajador";
import BuscadorCodigos from "./BuscadorCodigos";
import Reporte from "./Reporte";
import Comunicados from "./Comunicados";
import CuponPorCategoria from "./CuponporCategoria";
import registerServiceWorker from './registerServiceWorker';
import Notificaciones from './componentes/Notificaciones';

const root = ReactDOM.createRoot(document.getElementById("root"));
registerServiceWorker();

root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />

        <Route exact path="/panel/comunicado" element={<Comunicados />} />

        <Route
          exact
          path="/panel/comunicado/:id"
          element={<DetallesComunicado />}
        />
        <Route
          exact
          path="/panel/comunicado/agregarcomunicado"
          element={<FormAgregarComunicado />}
        />
		 <Route
          exact
          path="/panel/notificacion"
          element={<Notificaciones />}
        />
        <Route exact path="/panel/cupon" element={<Cupones />} />
        <Route exact path="/panel/usuarios" element={<Usuarios />} />
        <Route
          exact
          path="/panel/usuario/agregarusuario"
          element={<FormAgregarUsuario />}
        />
        <Route
          exact
          path="/panel/usuario/:id"
          element={<FormEditarUsuario />}
        />
        <Route
          exact
          path="/panel/cupon/agregarcupon"
          element={<AgregarCupon />}
        />
        <Route exact path="/panel/cupon/:id" element={<FormEditarCupon />} />
        <Route
          exact
          path="/panel/cupon/reporte/:idcupon"
          element={<Reporte />}
        />

        <Route exact path="/panel" element={<Panel />} />
        <Route exact path="/main" element={<MainTrabajador />} />
        <Route exact path="/main/:categoria" element={<CuponPorCategoria />} />
        <Route
          exact
          path="/main/cupones/:id"
          element={<DetallesCuponTrabajador />}
        />
        <Route
          exact
          path="/main/comunicados"
          element={<Comunicados />}
        />

        <Route exact path="/buscadorCodigos" element={<BuscadorCodigos />} />
        <Route exact path="/" element={<Login />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);
