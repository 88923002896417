import "../CSS/detallesComunicado.css";

import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { collection, getDoc, doc } from "firebase/firestore";
import { db } from "../FirebaseConfig/firebase";
import { async } from "@firebase/util";
import NavBar from "./NavBar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Carousel } from "react-responsive-carousel";

const MySwal = withReactContent(Swal);

function DetallesComunicado() {
  //Configurar hooks
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [detalle, setDetalle] = useState("");
  const [fecha, setFecha] = useState("");
  const [urlImagen, setUrlImagen] = useState("");

  const [archivo, setArchivo] = useState("");
  const [documentoUrl, setDocumentoUrl] = useState("");
  const [docVisible, setDocVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(true);
  const [datosCargados, setDatosCargados] = useState(false); // MOSTRAR BARRA DE CARGA

  const navigate = useNavigate();
  const { id } = useParams();
  //Renderizar contenido de QUILL
  const renderContent = () => {
    return { __html: detalle };
  };
  //obtener archivo comunicado

  const getURL = () => {
    const storage = getStorage();
    const pathReference = ref(storage, "archivosComunicados/" + archivo);
    getDownloadURL(pathReference)
      .then((url) => {
        console.log(url);
        setDocumentoUrl(url);
      })
      .catch((error) => {
        console.log("archivo no encontrado existe");
      });
  };
  //formatear fecha y hora
  const formatearFecha = (milisegundos) => {
    const fecha = new Date(milisegundos);
    const opcionesFecha = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const opcionesHora = {
      hour: "numeric",
      minute: "numeric",
    };

    const fechaFormateada = fecha.toLocaleDateString("es-ES", opcionesFecha);
    const horaFormateada = fecha.toLocaleTimeString("es-ES", opcionesHora);

    return `${fechaFormateada} - ${horaFormateada}`;
  };

  //ocultar gif de carga
  const handleImageLoad = () => {
    //console.log("a ver ");
    const timer = setTimeout(() => {
      setDatosCargados(true); // Cambiar el estado para mostrar el contenido después de 500ms
    }, 500);

    return () => clearTimeout(timer);
  };
  //Funcion para mostrar detalles del comunicados
  const getComunicadoById = async (id) => {
    const comunicado = await getDoc(doc(db, "COMUNICADOS", id));
    if (comunicado) {
      setTitulo(comunicado.data().titulo);
      setDescripcion(comunicado.data().descripcion);
      setDetalle(comunicado.data().detalle);
      setArchivo(comunicado.data().archivo);
      setFecha(comunicado.data().fecha.toMillis());
      setUrlImagen(comunicado.data().urlImagen);
    } else {
      console.log("no existe");
    }
  };
  //cerrar detalles comunicado
  const cerrar = () => {
    console.log("el nivel es" + localStorage.getItem("nivelUsuario"));
    if (localStorage.getItem("nivelUsuario") == "0") {
      navigate("../main/comunicados");
    } else {
      navigate("../panel/comunicado");
    }
  };
  useEffect(() => {
    getComunicadoById(id);
  }, []);
  useEffect(() => {
    if (!archivo == "") {
      setDocVisible(true);
      getURL();
      if (archivo.includes("mp4")) {
        setVideoVisible(false);
      }
    }
  }, [archivo]);

  useEffect(() => {
    getComunicadoById(id);
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px auto",
          textAlign: "center",
          height: !datosCargados ? "100vh" : "0px",
          justifyContent: "center",
          alignItems: "center",
          visibility: !datosCargados ? "visible" : "hidden",
        }}
      >
        <div style={{ verticalAlign: "middle", display: "block" }}>
          <div>
            <img
              style={{ height: "50px" }}
              src={require(`../img/vale.gif`)}
              alt="esperando"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          visibility: datosCargados ? "visible" : "hidden",
          display: datosCargados ? "block" : "none",
        }}
      >
        <NavBar
          titulo="Detalles del comunicado"
          nombre={""}
          imagen="cerrar"
          logoTitulo="comunicado"
          cerrar={cerrar}
        ></NavBar>
        <div className="dc-carousel">
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
          >
            <div>
              <img src={urlImagen} alt="Imagen 1" onLoad={handleImageLoad} />
            </div>
          </Carousel>
        </div>
        <div className="detallesComunicado">
          <div className="dc-grid-container">
            <div className="dc-grid-item">
              <form action="/" className="dc-form">
                <h3>{formatearFecha(fecha)}</h3>

                <h1 className="dc-titulo">{titulo}</h1>
                <h3>{descripcion}</h3>

                <p dangerouslySetInnerHTML={renderContent()}></p>
                <h3 className="dc-detalle"></h3>
              </form>
            </div>
            {docVisible ? (
              <div className="dc-grid-item">
                <h2 className="dc-label">RECURSOS:</h2>
                {videoVisible ? (
                  <div className="fac-panel-element">
                    <img
                      src={require(`../img/archivo.png`)}
                      type="submit"
                      alt="salir"
                      className="fac-salida"
                      style={{
                        margin: 15,
                      }}
                    />
                    <a href={documentoUrl} download="logo.png">
                      {archivo}
                    </a>
                  </div>
                ) : (
                  <div
                    className="fac-panel-element"
                    style={{
                      display: "flex",
                      backgroundColor: "#F0F4F8",
                      marginBottom: 20,
                      borderRadius: "30px",
                      width: "180px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require(`../img/vervideo.png`)}
                      type="submit"
                      alt="salir"
                      className="fac-salida"
                      style={{
                        margin: 15,
                      }}
                    />
                    <a href={documentoUrl} download="logo.png">
                      {"VER VIDEO"}
                    </a>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetallesComunicado;
