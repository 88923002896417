import "../CSS/itemCupon.css";
import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import React, { useState } from "react";

function ItemCuponTrabajador(props) {
  const [detVisible, setDetVisible] = useState(props.detalle);
  const [webVisible, setWebVisible] = useState(props.web);
  let link = props.redSocial;
  if (link && !link.startsWith("https://")) {
    // Agregar "https://" al inicio del enlace
    link = "https://" + link;
  }
  return (
    <div className="cupon-card">
      <div>
        <img
          id="imagenTienda"
          className="cupon-logoEmpresa"
          src={props.imagen}
          alt="flecha"
          onLoad={props.handleImageLoad}
        />
      </div>
      <div className="itemBody">
        <div className="cupon-cabecera">
          <p>{props.tienda}</p>
          <p>{props.descripcion}</p>
        </div>
        {detVisible ? (
          <div className="cupon-detalles">
            <Link>
              <p>Más detalles</p>
            </Link>
            <figure>
              <img
                className="cupon-flecha"
                src={require(`../img/flecha.png`)}
                alt=""
              />
            </figure>
          </div>
        ) : null}
      </div>
      {webVisible ? (
        <a href={link} target="_blank">
          <img
            className="cupon-icons cupon-borrar"
            src={require(`../img/red.png`)}
          />
        </a>
      ) : null}
    </div>
  );
}

export default ItemCuponTrabajador;
