import React, { useState, useEffect } from "react";
import NavBar from "./componentes/NavBar";
import ItemUsuario from "./componentes/ItemUsuario";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { db } from "./FirebaseConfig/firebase";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import "./CSS/usuarios.css";

const MySwal = withReactContent(Swal);

function Usuarios() {
  const navigate = useNavigate();
  const [dniUsuario, setDniUsuario] = useState("");
  const [nombre, setNombre] = useState("");
  const [nombresList, setNombresList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [visibleMaster, setVisibleMaster] = useState(true);

  //referenciar la DB
  const nList = collection(db, "USUARIOS");
  //funcion para mostrar la lista de comunicados
  const getUsuarios = async () => {
    const data = await getDocs(nList);
    setNombresList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log("asdasd" + nombresList);
  };
  function esNumero(value) {
    console.log("23123" + value);

    if (value) {
      if (!isNaN(value)) {
        console.log("es nsdumero");
        setListFilter(
          nombresList.filter((userItem) => String(userItem.dni).includes(value))
        );
        console.log(listFilter);
      } else {
        console.log("es nombre");

        setListFilter(
          nombresList.filter((userItem) =>
            String(userItem.nombre).toLowerCase().includes(value)
          )
        );
      }
    } else {
      setListFilter([]);
    }
  }
  //funcion para buscar usuarios por dni
  async function getUserByDni() {
    console.log("el clicl es " + dniUsuario);
    let fl = 0;
    const q = query(collection(db, "USUARIOS"), where("dni", "==", dniUsuario));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      fl = 1;
      console.log("INGRESO", doc.data());
      setNombre(doc.data().nombre);
    });
    if (fl == 0) {
      console.log("Usuario no encontrado");
    }
  }
  //Funcion para eliminar usuarios
  const deleteUsuario = async (id) => {
    MySwal.fire({
      title: "Eliminar usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar! ",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const usuario = doc(db, "USUARIOS", id);
        await deleteDoc(usuario);
        setListFilter([]);
        getUsuarios();

        Swal.fire("Eliminado!", "El usuario ha sido eliminado", "success");
      }
    });
  };
  const handleOnChange = (event) => {
    setDniUsuario(event.target.value);
    esNumero(event.target.value);
  };
 
  //cerrar cupones
  const cerrar = () => {
    navigate("../panel");
  };
  //uso de useEffect
  useEffect(() => {
    getUsuarios();
	/*if (localStorage.getItem("nivelUsuario") == "master") {
		setVisibleMaster(true);
	  }*/
  }, []);

  return (
    <div className="usuarios">
      <NavBar
        titulo="Usuarios"
        imagen="cerrar"
        logoTitulo="usuarios"
        cerrar={cerrar}
      ></NavBar>
      <div className="usuarios-buscador">
        <Box sx={{ width: 350 }}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <PersonSearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              sx={{ width: 350 }}
              label="Ingrese nombre o DNI"
              variant="standard"
              value={dniUsuario}
              onChange={handleOnChange}
            />
          </Box>
        </Box>
      </div>

      <div className="usuarios-item">
        {listFilter.map((item) => (
          <div className="usuarios-element" key={item.id}>
            <ItemUsuario
              nombre={item.nombre}
              dni={item.dni}
              id={item.id}
              sexo={item.sexo}
              estado={item.estado}
              fechaInicio={item.fechaInicio}
			  meses={item.meses}
			  apellido={item.apellido}
			  fecha={item.fechaInicio}
              deleteUsuario={deleteUsuario}
            />
          </div>
        ))}
      </div>
      <Link style={{ display: visibleMaster? "flex" : "none"  }} className="cupones-flotante" to={`/panel/usuario/agregarusuario`}>
        <img
          src={require(`./img/plus.png`)}
          className="cupones-boton"
          border="0"
        />
      </Link>
    </div>
  );
}

export default Usuarios;
