import "../CSS/formAgregarUsuario.css";
import IncrementadorNum from "./IncrementadorNum";
import React, { useRef, useEffect, useState, Fragment } from "react";
import NavBar from "./NavBar";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ItemUsuario from "./ItemUsuario";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import ContactMailRoundedIcon from "@mui/icons-material/ContactMailRounded";
import { storage } from "../FirebaseConfig/firebase";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import PasswordRoundedIcon from "@mui/icons-material/PasswordRounded";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import es from "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat"; // Opcional, para formato localizado

import dayjs from "dayjs"; // Importar Day.js
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import LinearProgress from "@mui/material/LinearProgress";

import { db } from "../FirebaseConfig/firebase";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es"; // Importar idioma español para Day.js
import { DevicesOtherSharp } from "@mui/icons-material";
import esLocale from "date-fns/locale/es"; // Importar el idioma español de date-fns
import { parse, format } from "date-fns";

dayjs.locale(es);
dayjs.extend(localizedFormat);
const MySwal = withReactContent(Swal);
function FormAgregarUsuario() {
  const [sexo, setSexo] = React.useState("");
  const [nivel, setNivel] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [apellido, setApellido] = React.useState("");
  const [celular, setCelular] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [meses, setMeses] = React.useState("");

  const [dni, setDni] = React.useState("");
  const [registro, setRegistro] = React.useState("");
  const [fecha, setFecha] = React.useState("");
  const [fechaString, setFechaString] = useState("");
  const [imagenLocal, setImagenLocal] = useState("imagen.png");
  const [pbVisibleImage, setpbVisibleImagen] = useState(false);
  const [urlImagen, setUrlImage] = useState("");
  const [contador, setContador] = useState("0");
  const [fechas, setFechas] = useState([]);

  const navigate = useNavigate();
  const comunicadosList = collection(db, "USUARIOS");

  const handleChange = (nuevaFecha) => {
    setFecha(nuevaFecha);
    // Aquí puedes hacer lo que necesites con la nueva fecha seleccionada
    console.log("Nueva fecha seleccionada:", nuevaFecha);
  };
  const handleChangeSex = (event) => {
	setSexo(event.target.value);
  };

  const handleChangeNiv = (event) => {
    setNivel(event.target.value);
  };
  const handleImageLoad = () => {
    setpbVisibleImagen(false);
    console.log(pbVisibleImage);
  };
  const calcularFecha = () => {
    let nuevasFechas = "";
    let fechaActual = dayjs(fecha);
    for (let i = 0; i <= contador; i++) {
      nuevasFechas = fechaActual.format("DD [de] MMMM [del] YYYY");
      fechaActual = fechaActual.add(1, "month");
    }
    console.log(nuevasFechas);
    setFechas(nuevasFechas);
  };

  const validarFormulario = (e) => {
    if (
      sexo == "" ||
      nivel == "" ||
      nombre == "" ||
      apellido == "" ||
      celular == "" ||
      dni == "" ||
      registro == "" ||
      correo == "" ||
      fecha == ""
    ) {
      MySwal.fire("", "Llenar todos los campos", "error");
    } else {
      store(e);
    }
  };
  const subirImagen = async (e) => {
    const archivo = e.target.files[0];
    setpbVisibleImagen(true);
    var today = new Date();
    var prefijoImagen =
      today.getDate().toString() +
      today.getHours().toString() +
      today.getMinutes().toString() +
      today.getSeconds().toString();

    const storageRef = ref(
      storage,
      "imagenesUsuarios/" + prefijoImagen + archivo.name
    );

    uploadBytes(storageRef, archivo).then((snapshot) => {
      const storage = getStorage();
      getDownloadURL(
        ref(storage, "imagenesUsuarios/" + prefijoImagen + archivo.name)
      )
        .then((url) => {
          setUrlImage(url);
          console.log("URL " + url);
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            const blob = xhr.response;
          };
          xhr.open("GET", url);
          xhr.send();
          // Or inserted into an <img> element
          const img = document.getElementById("imagenUsuario");
          img.setAttribute("src", url);
        })
        .catch((error) => {
          console.log("error " + error);
        });
    });
  };
  const store = async (e) => {
    e.preventDefault();

    MySwal.fire({
      title: "Agregar Usuario? ",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Agregar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await addDoc(comunicadosList, {
          sexo: sexo,
          nombre: nombre,
          apellido: apellido,
          celular: celular,
          correo: correo,
          nroRegistro: registro,
          dni: dni,
          nivel: nivel,
          fechaInicio: dayjs(fecha).format("YYYY/MM/DD"),
          meses: meses,
          urlImagen: urlImagen,
        });
        Swal.fire("Agregado", "El usuario ha sido agregado", "success");
        navigate("/panel/usuarios");
      }
    });
  };
  //cerrar detalles comunicado
  const cerrar = () => {
    navigate("../panel/usuarios");
  };
  //uso de useEffect
  useEffect(() => {
    setFecha(dayjs(new Date()));
    setContador(1);
  }, []);
  //uso de useEffect
  useEffect(() => {
    calcularFecha();
    setMeses(contador);
  }, [contador]);
  useEffect(() => {
    console.log(dayjs(fecha).format("DD/MM/YYYY"));
    calcularFecha();
  }, [fecha]);
  return (
    <Fragment>
      <NavBar
        titulo="Agregar Usuario"
        imagen="cerrar"
        logoTitulo="usuarios"
        cerrar={cerrar}
      ></NavBar>
      <div className="fau-agregarUsuarios">
        <div className="fau-card">
          <div className="fac-divImagen">
            <div className="fau-image-upload">
              <label htmlFor="file-input">
                <img
                  id="imagenUsuario"
                  src={
                    urlImagen == ""
                      ? sexo == "Mujer"
                        ? require(`../img/usuariowoman.png`)
                        : require(`../img/usuarioman.png`)
                      : urlImagen
                  }
                  alt="logo-titulo"
                  className="fac-imagenEmpresa"
                  onLoad={handleImageLoad}
                />
              </label>
              <input
                className="btnAgrearImagenCupon"
                id="file-input"
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                onChange={subirImagen}
              />
            </div>
          </div>
          {pbVisibleImage ? (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <LinearProgress
                style={{ display: "flex", width: "300px", marginTop: "20px" }}
                color="success"
              />
            </div>
          ) : null}

          <div className="fau-formNumber">
            <Box sx={{ width: 300 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sexo}
                  label="Sexo"
                  onChange={handleChangeSex}
                >
                  <MenuItem value={"Hombre"}>Hombre</MenuItem>
                  <MenuItem value={"Mujer"}>Mujer</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="fau-formNumber">
            <Box sx={{ width: 350 }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <PersonRoundedIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  sx={{ width: 350 }}
                  label="Nombre(s)"
                  value={nombre}
                  variant="standard"
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Box>
            </Box>
          </div>
          <div className="fau-formNumber">
            <Box sx={{ width: 350 }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <PersonRoundedIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  sx={{ width: 350 }}
                  label="Apellidos"
                  value={apellido}
                  variant="standard"
                  onChange={(e) => setApellido(e.target.value)}
                />
              </Box>
            </Box>
          </div>
          <div className="fau-formNumber">
            <Box sx={{ width: 350 }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <PhoneIphoneIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  sx={{ width: 350 }}
                  label="Nro de celular"
                  value={celular}
                  type="number"
                  variant="standard"
                  onChange={(e) => setCelular(e.target.value)}
                />
              </Box>
            </Box>
          </div>
          <div className="fau-formNumber">
            <Box sx={{ width: 350 }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <AlternateEmailIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  sx={{ width: 350 }}
                  label="Correo"
                  value={correo}
                  variant="standard"
                  onChange={(e) => setCorreo(e.target.value)}
                />
              </Box>
            </Box>
          </div>
          <div className="fau-formNumber">
            <Box sx={{ width: 350 }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <ContactMailRoundedIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  sx={{ width: 350 }}
                  label="Nro Registro"
                  variant="standard"
                  type="number"
                  value={registro}
                  onChange={(e) => setRegistro(e.target.value)}
                />
              </Box>
            </Box>
          </div>

          <div className="fau-formNumber">
            <Box sx={{ width: 350 }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <PasswordRoundedIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  sx={{ width: 350 }}
                  label=" DNI"
                  variant="standard"
                  type="number"
                  value={dni}
                  onChange={(e) => setDni(e.target.value)}
                />
              </Box>
            </Box>
          </div>

          <div className="fau-formNumber">
            <Box sx={{ width: 300 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Nivel de usuario
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={nivel}
                  label="Nivel de usuario"
                  onChange={handleChangeNiv}
                >
                  <MenuItem value={"0"}>Cliente</MenuItem>
                  <MenuItem value={"1"}>Empresa</MenuItem>
				  {localStorage.getItem("nivelUsuario") == "admin"?null:<MenuItem value={"admin"}>Administrador</MenuItem>}
                  
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="fau-formNumber">
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <Stack spacing={3} sx={{ width: 300 }}>
                <DatePicker
                  label="Fecha de inicio del plan"
                  value={fecha}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                  locale={"es"} // Establecer el idioma a español
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="fau-formNumber">
            <label className="label" style={{ marginRight: "40px" }}>
              Meses
            </label>
            <IncrementadorNum
              style={{ margin: "30px" }}
              contador={contador}
              setContador={setContador}
            ></IncrementadorNum>
          </div>
          <div className="fau-formNumber">
            <label className="label" style={{ marginRight: "40px" }}>
              Su plan termina el {fechas}
            </label>
          </div>
          <div className="fau-formNumber">
            <div className="fau-addUser">
              <input
                type="submit"
                value="Agregar Usuario"
                className="fau-btnAgregarComunicado"
                onClick={validarFormulario}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FormAgregarUsuario;
