import React, { useState, useEffect } from "react";
import NavBar from "./componentes/NavBar";
import ItemCodigoCupon from "./componentes/ItemCodigoCupon";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

import {
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import SearchIcon from "@mui/icons-material/Search";

import { db } from "./FirebaseConfig/firebase";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import "./CSS/usuarios.css";

const MySwal = withReactContent(Swal);

function BuscadorCodigos() {
  const navigate = useNavigate();
  const [dniUsuario, setDniUsuario] = useState("");
  const [detableVisivle, setDetallesVisible] = useState(false);

  const [usuario, setUsuario] = useState({ nombre: "", dni: "", sexo: "" });
  const [cupon, setCupon] = useState({
    tienda: "",
    urlImagen: "",
    detalle: "",
    descripcion: "",
  });

  const [textoCodigo, setTextoCodigo] = useState("USAR CUPON");
  const [codigo, setCodigo] = useState();
  const [pbVisible, setpbVisible] = useState(true);
  
  const [nombresList, setNombresList] = useState([]);
  const [cuponList, setCuponList] = useState([]);
  const [codigosList, setCodigosList] = useState([]);

  const [listFilter, setListFilter] = useState([]);

  //referenciar la DB
  const nList = collection(db, "USUARIOS");
  const cuponesList = collection(db, "CUPONES");
  //funcion para mostrar la lista de comunicados
  const getCupones = async () => {
    const data = await getDocs(cuponesList);
    setCuponList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    //console.log(cuponList);
  };

  //funcion para buscar usuarios por dni
  async function getUserByDni(dni) {
    const q = query(collection(db, "USUARIOS"), where("dni", "==", dni));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setUsuario(doc.data());
    });
  }

  const handleOnChange = (event) => {
    setDniUsuario(event.target.value);
  };
  //cerrar cupones
  const cerrar = () => {
    MySwal.fire({
      title: "Cerrar sesión?",
      text: "Desea cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar sesión",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("idLocal", "");
        localStorage.setItem("nombreLocal","")
        navigate("../login");
      }
    });
  };
  const confirUsarCodigo = (e) => {
    MySwal.fire({
      title: "Usar código? ",
      text: "El usuario ya no podra usar el código nuevamente",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Usar",
    }).then((result) => {
      if (result.isConfirmed) {
        usarCodigo(e);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Código usado",
          showConfirmButton: false,
          timer: 1500,
        });
        setDetallesVisible(false);
        setCodigosList([]);
        buscarCodigos();
        setDniUsuario("");
      }
    });
  };
  //cerrar cupones
  const usarCodigo = async (e) => {
    e.preventDefault();
    const codigoDB = doc(db, `CUPONES/${codigo.idCupon}/Codigos`, codigo.id);
    const data = {
      codigo: codigo.codigo,
      estado: "Usado",
      idUsuario: codigo.idUsuario,
      fecha: new Date()
        .toLocaleString("es-ES", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        .toString(),
    };
    await setDoc(codigoDB, data);
    console.log(codigo);
  };
  //FOR DE FORS
  function buscar() {
    const result = codigosList.find(({ codigo }) => codigo === dniUsuario);
    console.log(result)
    if(result===undefined){
      Swal.fire(
        "CUPÓN NO ENCONTRADO",
        "Escribir correctamente el cupón",
        "error"
      );
      return;
    }
    setCodigo(result);
    rellenarDatos(result.idCupon, result.idUsuario);
    //console.log(result);
    if (result.estado === "Usado") {
      Swal.fire(
        "CUPÓN USADO",
        `Este cupón fue usado el dia : ${result.fecha}`,
        "error"
      );
    } else {
      if (result.estadoCupon === "Inactivo") {
        Swal.fire("CUPÓN EXPIRADO", `Este cupon ya expiró`, "error");
        setDetallesVisible(false);
      } else {
        setDetallesVisible(true);
      }
    }

    //console.log(result);
  }
  async function rellenarDatos(idCupon, dniU) {
    const docRef = doc(db, "CUPONES", idCupon);
    const docSnap = await getDoc(docRef);
    setCupon(docSnap.data());
    getUserByDni(dniU);
  }
  //FOR DE FORS
  const buscarCodigos = async () => {
    for (let i = 0; i < cuponList.length; i++) {
      const cuponesList = collection(db, `CUPONES/${cuponList[i].id}/Codigos`);
      const data = await getDocs(cuponesList);
      setCodigosList((codigosList) => [
        ...codigosList,
        ...data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          idCupon: cuponList[i].id,
          estadoCupon: cuponList[i].estado,
        })),
      ]);
    }
    setpbVisible(false)

  };
  //uso de useEffect
  useEffect(() => {
    getCupones();
  }, []);
  useEffect(() => {
    //console.log(cuponList);
    buscarCodigos();
  }, [cuponList]);
  useEffect(() => {
    console.log("hola")
  }, [codigosList]);
  useEffect(() => {
    //console.log(cupon);
  }, [cupon]);
  useEffect(() => {
    
  }, [codigo]);
  return (
    <div className="usuarios">
      <NavBar
        titulo="Buscador de códigos"
        imagen="salir"
        logoTitulo="cupon"
        cerrar={cerrar}
      ></NavBar>
      {pbVisible ? (<LinearProgress color="success" style={{ marginBottom: 20 }} />):null}
      <div className="usuarios-buscador">
        <Box sx={{ width: 350 }}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              sx={{ width: 350 }}
              label="Ingrese codigo del cupon "
              variant="standard"
              value={dniUsuario}
              onChange={handleOnChange}
            />
            <div onClick={buscar}>
              <SearchIcon
                type="button"
                sx={{ color: "action.active", mr: 2, my: 0.5 }}
                onClick={() => buscar}
              />
            </div>
          </Box>
        </Box>
      </div>
      {detableVisivle ? (
        <div>
          <div className="usarCupon">
            <input
              type="submit"
              value={textoCodigo}
              className="btnAgregarComunicado"
              onClick={confirUsarCodigo}
            />
          </div>
          <div className="usuarios-item">
            <ItemCodigoCupon
              nombre={usuario.nombre}
              dni={usuario.dni}
              tienda={cupon.tienda}
              imagen={cupon.urlImagen}
              detalle={cupon.detalle}
              descripcion={cupon.descripcion}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default BuscadorCodigos;
