import React, { useState, useEffect, Component } from "react";
import NavBar from "./componentes/NavBar";
//import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "./FirebaseConfig/firebase";
import { orderBy, limit } from "firebase/firestore";
import ItemPanel from "./componentes/ItemPanel";
import styles from "./CSS/mainTrabajador.module.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
import ItemCuponTrabajador from "./componentes/ItemCuponTrabajador";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const MySwal = withReactContent(Swal);
function CuponPorCategoria() {
  //configurar useState
  const [cupones, setCupones] = useState([]);
  const [nombre, setNombre] = useState("");
  const [datosCargados, setDatosCargados] = useState(false); // MOSTRAR BARRA DE CARGA

  //referenciar la DB
  const cuponesList = collection(db, "CUPONES");

  //lista de categorias
  const elementos = [
    { nombre: "Diversion", imagen: "diversion" },
    { nombre: "Salud", imagen: "salud" },
    { nombre: "Formacion", imagen: "Formacion" },
    { nombre: "Restaurante", imagen: "restaurantes" },
    { nombre: "Moda", imagen: "moda" },
  ];

  //obtener url
  const { categoria } = useParams();

  //Funcion para mostrar cupones del usuario
  const getCuponesByEstado = async () => {
    const q = query(
      collection(db, "CUPONES"),
      where("estado", "==", "Activo"),
      where("categoria", "==", categoria)
    );
    const querySnapshot = await getDocs(q);
    setCupones(
      querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  //funcion para mostrar la lista de cupones
  const getCupones = async () => {
    const data = await getDocs(cuponesList);
    setCupones(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log(cupones);
  };
  //ocultar gif de carga
  const handleImageLoad = () => {
    //console.log("a ver ");
    setDatosCargados(true);
  };
  const navigate = useNavigate();
  //cerrar sesion
  const salir = () => {
    navigate("../main");
  };
  //cerrar comunicados
  const abrirFormulario = () => {
    navigate("../panel");
  };
  //uso de useEffect
  useEffect(() => {
    getCuponesByEstado();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("idLocal") == "") {
      const idLocal = localStorage.getItem("idLocal");
      console.log("NO ES USER");
      navigate("../login");
    } else {
      setNombre(localStorage.getItem("nombreLocal"));
    }
    console.log(cupones);
  }, []);
  useEffect(() => {
    if (cupones.length == 0) {
      setDatosCargados(true);
    }
  }, [cupones]);
  if (!datosCargados) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px auto",
          textAlign: "center",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ verticalAlign: "middle", display: "block" }}>
          <div>
            <img
              style={{ height: "300px" }}
              src={require(`./img/globo.gif`)}
              alt="esperando"
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div
        className={styles.comunicados}
        style={{
          visibility: datosCargados ? "visible" : "hidden",
        }}
      >
        <NavBar
          titulo="CUPONES"
          nombre=""
          imagen="cerrar"
          logoTitulo="cupon"
          cerrar={salir}
        ></NavBar>
        <h1 className={styles.tituloComunicado}>{categoria}</h1>
        {cupones.length == 0 ? (
          <div style={{margin:"40px"}}>No hay cupones disponibles</div>
        ) : (
          <div className={styles.gridContainerCategoria}>
            {cupones.map((item) => (
              <div
                className={styles.element}
                onClick={() => navigate("../main/cupones/" + item.id)}
              >
                <ItemCuponTrabajador
                  tienda={item.tienda}
                  descripcion={item.descripcion}
                  id={item.id}
                  categoria={item.categoria}
                  redSocial={item.redSocial}
                  imagen={item.urlImagen}
                  detalle={true}
                  web={false}
                  handleImageLoad={handleImageLoad}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default CuponPorCategoria;
