import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Asegúrate de haber incluido esta línea

const QuillEditor = (props) => {

  const handleChange = (value) => {
    props.setContent(value);
  };

  return (
    <div>
      <ReactQuill
        value={props.content}
        onChange={handleChange}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
            ['link', 'image'],
          ],
        }}
      />
     
    </div>
  );
};

export default QuillEditor;