import "../CSS/itemCupon.css";
import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

function ItemComunicado(props) {
  const [nivelUsuario, setNivelUsuario] = useState("");

 /* useEffect(() => {
    if (localStorage.getItem("idLocal") == "") {
      console.log("NO ES USER");
    } else {
      const nivelUsuario = localStorage.getItem("nivelUsuario");
      console.log(nivelUsuario);
	  setNivelUsuario
    }
  }, []);*/
  return (
    <div className="cupon-card">
      <div>
        <img
          id="imagenTienda"
          className="cupon-logoEmpresa"
          src={props.imagen}
          alt="flecha"
        />
      </div>
      <div className="itemBody">
        <div className="cupon-cabecera">
          <p>{props.tienda}</p>
          <p>{props.descripcion}</p>
        </div>
        <div className="cupon-detalles">
          <Link to={`/panel/cupon/${props.id}`}>
            <p>Editar</p>
          </Link>
          <figure>
            <img
              className="cupon-flecha"
              src={require(`../img/flecha.png`)}
              alt=""
            />
          </figure>
        </div>
      </div>

      <img
        className="cupon-icons cupon-borrar"
        src={require(`../img/borrar.png`)}
        alt=""
        onClick={() => props.deleteCupon(props.id)}
      />
    </div>
  );
}

export default ItemComunicado;
