import NavBar from "./componentes/NavBar";
import "./CSS/reporte.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { db } from "./FirebaseConfig/firebase";

import { CSVLink } from "react-csv";

function Reporte() {
  const navigate = useNavigate();
  const { idcupon } = useParams();

  const cuponesList = collection(db, "CUPONES");
  const [codigosList, setCodigosList] = useState([]);
  const [cuponesUsados, setCuponesUsados] = useState(0);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  //FOR DE FORS
  const buscarCodigos = async () => {
    const cuponesList = collection(db, `CUPONES/${idcupon}/Codigos`);
    const data = await getDocs(cuponesList);
    setCodigosList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //cerrar cupones
  const cerrar = () => {
    navigate(`../panel/cupon/${idcupon}`);
  };
  useEffect(() => {
    buscarCodigos();
  }, []);
  useEffect(() => {
    setCuponesUsados(
      codigosList.filter((codigo) => String(codigo.estado).includes("Usado"))
        .length
    );
  }, [codigosList]);
  return (
    <div className="panel">
      <NavBar
        titulo="REPORTES"
        nombre=""
        imagen="cerrar"
        logoTitulo="cupon"
        cerrar={cerrar}
      ></NavBar>
      <div className="reporte-cabecera">
        <h1 className="cd">Códigos generados: {codigosList.length}</h1>
        <h1 className="cd">Códigos usados: {cuponesUsados}</h1>
      </div>
      <CSVLink
        data={codigosList}
        filename="ReporteCupon"
        className="btn btn-success  m-5 mb-1 mt-1"
      >
        Descargar Reporte
      </CSVLink>

      <div className="reporte-tabla">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b>USUARIO</b>
                </TableCell>
                <TableCell align="center">
                  <b>CODIGO</b>
                </TableCell>
                <TableCell align="center">
                  <b>ESTADO</b>
                </TableCell>
                <TableCell align="center">
                  <b>FECHA</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {codigosList.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.idUsuario}</TableCell>
                  <TableCell align="center">{row.codigo}</TableCell>
                  <TableCell align="center">{row.estado}</TableCell>
                  <TableCell align="center">{row.fecha}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Reporte;
