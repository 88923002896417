import { getAuth, signInAnonymously } from "firebase/auth";
import { getToken, onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import { app } from "./FirebaseConfig/firebase";

//import addNotification from "react-push-notification";
import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/**************************** */
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/messaging";
/* ***************************/
var messaging;

if (firebase.messaging.isSupported()) {
  console.log("El navegador si admite la API de notificaciones de Firebase");
  messaging = getMessaging(app);
  requestPermission();
} else {
  console.log("El navegador no admite la API de notificaciones de Firebase");
}

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      //console.log("Notification permission granted.");

      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey:
          "BJ_6kigCM5iMCO9kKXDA1eIvZybz47PENKVAgH73WmceUojETG6sagQiNhvpNym0ySJs3S8cddgs9LTFtlqz3Ss",
      }).then((currentToken) => {
        if (currentToken) {
          console.log("token actual: ", currentToken);
          subscribeTokenToTopic(currentToken, "all");
        } else {
          console.log("Can not get token");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}
function subscribeTokenToTopic(token, topic) {
  fetch("https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic, {
    method: "POST",
    headers: new Headers({
      Authorization:
        "key=" +
        "AAAANStXOFY:APA91bFoKpgbGz_GYpj-TaynD6fMBuDXkMoinnCbn5lmdyguxPut1phAicar8mxn4QEgT-4QvnGQew2reohSYpSIVVVr3mjrntB4UbTyBuf1ABpEt7r_wgkuCjU-yQSwyxyhsSenJ2Er",
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw (
          "Error subscribing to topic: " +
          response.status +
          " - " +
          response.text()
        );
      }
      console.log('Subscribed to "' + topic + '"');
    })
    .catch((error) => {
      console.error(error);
    });
}

function NotificationButton() {
  const loguearse = () => {
    signInAnonymously(getAuth()).then((usuario) => console.log(usuario));
  };
  /*
  const activarMensajes = async () => {
    const token = await getToken(messaging, {
      vapidKey:
        "dZKGHIonq4AmAFZtYddCVy:APA91bE0PrW8078RLyRcyrK5MfQOf_arHKcUyj91ChCORKdA5siT3KVeKGQLxzMiY3slEdzMW9J9lxtB1KT2nvyYQNL0aawafyoAtp_9Nr4N0Vt1GRb6z7v5QfS1rrzYKKFDv0aQXH5L",
    }).catch((error) =>
      console.log("Tuviste un error al generar el token, papá")
    );

    if (token) console.log("tu token:", token);
    if (!token) console.log("no tienes token, rey");
  };
/*
  const handleNotificationClick = () => {
    console.log("trueeee");

    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          // Obtener el token de registro del dispositivo
          messaging2
            .getToken({
              vapidKey:
                "BIvcmuoEzg-z_9zr_GGmbtRj94j5flrFGgGgTUA1wQ2xGz2XTi26LzdLiIPVdkrK4MsmgUACs9CSDqtHsN7j_7w",
            }) // Reemplaza 'TU_PUBLIC_VAPID_KEY' con la clave pública VAPID de tu servidor
            .then((currentToken) => {
              // Enviar la notificación push utilizando el token de registro y el contenido del input
              const notification = {
                title: "Aver",
                body: "Contenido de la notificación...",
                icon: "/path/to/icon.png", // Ruta a un ícono para la notificación (opcional)
                click_action: "https://tu-sitio-web.com", // URL que se abrirá al hacer clic en la notificación (opcional)
              };

              // Envía la notificación push utilizando FCM
              messaging
                .sendMessage({
                  token: currentToken,
                  notification: notification,
                })
                .then(() => {
                  console.log("Notificación push enviada con éxito!");
                })
                .catch((error) => {
                  console.error("Error al enviar la notificación push:", error);
                });
            })
            .catch((err) => {
              console.error("Error al obtener el token de registro:", err);
            });
        }
      })
      .catch((err) => {
        console.error("Error al solicitar permiso de notificación:", err);
      });
  };
*/

  /*
  const buttonClick = () => {
    toast("Asdasd");

    addNotification({
      title: "Warning",
      subtitle: "This is a subtitle",
      message: "This is a very long message",
      theme: "darkblue",
      native: true, // when using native, your OS will handle theming.
      onClick: () => console.log("NOTI"),
    });
  };
*/

  /***************************************** */

  /***************************************** */

  const [mensaje, setMensaje] = useState("");

  const enviarSolicitud = () => {
    const url = "https://fcm.googleapis.com/fcm/send "; // Reemplaza con la URL de tu API
    // Datos que deseas enviar en el cuerpo de la solicitud POST
    const datos = {
      notification: {
        title: "NUEVO CUPON",
        body: "Aprovecha los mejores precios de nuestros afiliados",
        click_action: "http://localhost:3000/",
        icon: "/cupon.png",
      },
      to: "/topics/all",
    };
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Especifica el tipo de contenido en el cuerpo de la solicitud
        Authorization:
          "key=AAAANStXOFY:APA91bFoKpgbGz_GYpj-TaynD6fMBuDXkMoinnCbn5lmdyguxPut1phAicar8mxn4QEgT-4QvnGQew2reohSYpSIVVVr3mjrntB4UbTyBuf1ABpEt7r_wgkuCjU-yQSwyxyhsSenJ2Er",
      },
      body: JSON.stringify(datos), // Convierte el objeto de datos a formato JSON
    })
      .then((response) => response.json()) // Parsea la respuesta como JSON
      .then((data) => {
        // Aquí puedes manejar la respuesta del servidor
        setMensaje(data.mensaje); // Por ejemplo, mostrar un mensaje de éxito o error
      })
      .catch((error) => {
        // Aquí puedes manejar cualquier error ocurrido durante la solicitud
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    //console.log("tu mensajrrrrre:");
    if (messaging != null) {
      onMessage(messaging, (message) => {
        //console.log("tu mensaje:", message.notification.title);
        toast(message.notification.title);

        if ("Notification" in window && Notification.permission === "granted") {
          const notificationOptions = {
            body: message.notification.body,
            icon: "./notificacion.png",
          };
          const notification = new Notification(
            message.notification.title,
            notificationOptions
          );

          notification.onclick = function () {
            // Aquí puedes redirigir al enlace que desees
            window.location.href = "https://www.edrups.com/imperiumapp"; // Reemplaza esta URL con la que quieras redirigir
          };
        }
      });
    }
  }, []);

  return (
    <div>
      <ToastContainer />
    </div>
  );
}

export default NotificationButton;
