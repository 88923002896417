import "../CSS/detallesCuponTrabajador.css";
import React, { useState, useEffect } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import NavBar from "./NavBar";
import { Fragment } from "react";
import ItemCuponTrabajador from "./ItemCuponTrabajador";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  getDoc,
  doc,
  deleteDoc,
  query,
  where,
  addDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../FirebaseConfig/firebase";
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

function DetallesCuponTrabajador(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const codigosList = collection(db, `CUPONES/${id}/Codigos`);

  const [idTrabajador, setIdTrabajador] = useState(
    localStorage.getItem("idLocal")
  );
  const [codigos, setCodigos] = useState([]);
  const [cupon, setCupon] = useState([]);
  const [cuponUsado, setCuponUSado] = useState(0);
  const [cuponesDisponibles, setCuponesDisponibles] = useState(0);

  const [codigo, setCodigo] = useState("Generar Código");
  const [disable, setDisable] = React.useState(false);
  const cadenaAleatoria = (longitud) => {
    // Nota: no uses esta función para cosas criptográficamente seguras.
    const banco = "ABCDEFGHJKLMNPQRSTUVWXYZ01234567890123456789";
    let aleatoria = "";
    for (let i = 0; i < longitud; i++) {
      // Lee más sobre la elección del índice aleatorio en:
      // https://parzibyte.me/blog/2021/11/30/elemento-aleatorio-arreglo-javascript/
      aleatoria += banco.charAt(Math.floor(Math.random() * banco.length));
    }
    return aleatoria;
  };
  //Funcion para mostrar detalles del usuario
  const getCuponesByID = async () => {
    const docRef = doc(db, "CUPONES", id);
    const docSnap = await getDoc(docRef);
    setCupon(docSnap.data());
    //console.log(docSnap.data());
  };
  //Funcion para mostrar cupones del usuario
  const getCuponesByCategoria = async () => {
    let ruta = `CUPONES/${id}/Codigos`;
    const q = query(
      collection(db, ruta),
      where("idUsuario", "==", idTrabajador)
    );
    const querySnapshot = await getDocs(q);
    setCodigos(
      querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };
  //generar cupon
  const generarCupon = async () => {
    let numeros = cadenaAleatoria(5);
    setCodigo(numeros);
    setDisable(true);
    await addDoc(codigosList, {
      codigo: numeros,
      idUsuario: idTrabajador,
      estado: "Activo",
    });
  };

  //uso de useEffect
  useEffect(() => {
    getCuponesByID();
    getCuponesByCategoria();
  }, []);
  useEffect(() => {
    let asd = cupon.cuponXusuario;

    let cuponesUsados = 0;
    if (codigos.length > 0) {
      codigos.map((cod) => {
        if (cod.estado == "Activo") {
          setCodigo(cod.codigo);
          setDisable(true);
        } else {
          console.log("123");
          cuponesUsados++;
        }
      });
    }
    setCuponUSado(cuponesUsados);
    if (cupon.cuponXusuario === "ilimitado") {
      setCuponesDisponibles("Ilimitado");
    } else {
      setCuponesDisponibles(cupon.cuponXusuario - cuponUsado);
    }

    console.log("assssssd" + cuponesUsados);
    if (cuponesUsados === cupon.cuponXusuario) {
      setCodigo("CUPÓN AGOTADO");
      setDisable(true);
    }
  }, [codigos]);
  //cerrar editar usuario
  const cerrar = () => {
    navigate("../main");
  };
  return (
    <Fragment>
      <NavBar
        titulo=" "
        imagen="cerrar"
        logoTitulo="blanco"
        cerrar={cerrar}
      ></NavBar>
      <div className="dct-detalles">
        <div className="dct-itemCupon">
          <ItemCuponTrabajador
            tienda={cupon.tienda}
            descripcion={cupon.descripcion}
            categoria={cupon.categoria}
            imagen={cupon.urlImagen}
            detalle={false}
            web={true}
            redSocial={cupon.redSocial}
          />
        </div>

        <div className="cupon-card">
          <div className="dct-buttons" >
            <div className="dct-button" >
              <img
                id="imagenTienda"
                className="dct-icons"
                src={require(`../img/placeholder.png`)}
                alt="flecha"
              />
              <a style= {{textDecoration: "none " , color:"black", paddingLeft : "12px"}}  href={cupon.urlMapa} target="_blank">Ubicación</a>

            </div>
            <div className="dct-button">
              <img
                id="imagenTienda"
                className="dct-icons"
                src={require(`../img/cupones.png`)}
                alt="flecha"
              />
              <a   style= {{textDecoration: "none " , color:"black",paddingLeft : "10px"}}  href={cupon.urlDrive}target="_blank"> Promociones</a>
            </div>
          </div>
        </div>

        <div className="dct-contenedor">
          <div className="dct-cardDetalles">
            <TextareaAutosize
              className="inputdetalles"
              aria-label="empty textarea"
              placeholder="Empty"
              value={cupon.detalle}
            />

            <div>Cupones disponibles: {cuponesDisponibles}</div>

            <div className="generarCupon">
              <input
                disabled={disable}
                type="submit"
                value={codigo}
                onClick={generarCupon}
                className="btnAgregarComunicado"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DetallesCuponTrabajador;
