import "../CSS/itemUsuario.css";
import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

function ItemUsuario(props) {
  const [sexo, setSexo] = useState("usuarioman");
  const [estado, setEstado] = useState("#E1F4B2");
  const [texto, setTexto] = useState("Activo");
  const [visibleMaster, setVisibleMaster] = useState(true);

  //Verificar si esta activo o no
  const verificarFecha = (fechaStr, meses) => {

    const [dia, mes, año] = fechaStr.split("/");

    // Crear un nuevo objeto Date
    const fechaOriginal = new Date(`${año}/${mes}/${dia}`);

    const fechaActual = new Date();
    console.log("fecha original");
    console.log(fechaOriginal);
    console.log("fecha actual");
    console.log(fechaActual);
    const nuevaFecha = new Date(
      fechaOriginal.setMonth(fechaOriginal.getMonth() + meses)
    );
    if (nuevaFecha <= fechaActual) {
      setEstado("#FF8D8D");
      setTexto("Inactivo");
    } else {
      setEstado("#E1F4B2");
      setTexto("Activo");
    }
    return nuevaFecha <= fechaActual;
  };
  useEffect(() => {
    if (props.sexo == "Mujer") setSexo("usuariowoman");
    if (props.estado == "Inactivo") setEstado("#FF8D8D");
    verificarFecha(props.fechaInicio, props.meses);
   /* if (localStorage.getItem("nivelUsuario") == "master") {
      setVisibleMaster(true);
    }*/
  }, []);

  return (
    <div className="user-card">
      <div className="user-infocard">
        <img
          id="imagenTienda"
          className="user-icon" 
          src={require(`../img/${sexo}.png`)}
          alt="flecha"
        />
        <div className="user-info">
          <div>
            <h5 className="user-nombre">
              {props.nombre} {props.apellido}
            </h5>

            <small>{props.dni}</small>
          </div>
        </div>
        <Link
          style={{ display: visibleMaster ? "flex" : "none" }}
          to={`/panel/usuario/${props.id}`}
        >
          <img
            id="imagenTienda"
            className="user-icon"
            src={require(`../img/editar.png`)}
            alt="flecha"
          />
        </Link>

        <img
          style={{ display: visibleMaster ? "flex" : "none" }}
          className="user-icon"
          src={require(`../img/borrar.png`)}
          alt="borrar"
          onClick={() => props.deleteUsuario(props.id)}
        />
        <div className="user-state">
          <h5
            className="user-textState"
            style={{ backgroundColor: `${estado}` }}
          >
            {texto}
          </h5>
        </div>
      </div>
    </div>
  );
}

export default ItemUsuario;
