import "../CSS/itemPanel.css";
function ItemPanel(props) {
	console.log(props.imagen)
  return (
    <div className="item-panel">

      <img
        className="imagen-item"
        src={require(`../img/${props.imagen}.png`)}
        alt="ITEM"
      />
      <div className="nombre-item">
        <strong>{props.nombre}</strong>
      </div>
    </div>
  );
}

export default ItemPanel;
