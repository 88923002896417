import hola from "../img/hola.png";
import "../CSS/navbar.css";
import React, { useRef, useEffect, useState } from "react";

function IncrementadorNum({ contador, setContador }) {
  return (
    <div className="navbar-center">
      <img
        src={require(`../img/menos.png`)}
        alt="logo-titulo"
        onClick={() => {
          if (contador > 0) setContador(contador - 1);
        }}
        style={{ height: "40px", marginRight: "20px" }}
      />
      <h1 className="titulo" style={{ color: "black", margin: "0px" }}>
        {contador}
      </h1>
      <img
        src={require(`../img/mas.png`)}
        alt="logo-titulo"
        onClick={() => {
          if(contador=="ilimitado"){
            setContador(1);
          }
          else{
            setContador(parseInt(contador) + 1);

          }
        }}
        style={{ height: "40px", marginLeft: "20px" }}
      />
      <img
        src={require(`../img/ilimitado.png`)}
        alt="logo-titulo"
        onClick={() => setContador("ilimitado")}
        style={{ height: "40px", marginLeft: "20px" }}
      />
    </div>
  );
}

export default IncrementadorNum;
