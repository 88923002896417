import "../CSS/formAgregarCupon.css";
import IncrementadorNum from "./IncrementadorNum";
import React, { useRef, useEffect, useState, Fragment } from "react";
import NavBar from "./NavBar";

import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LinearProgress from "@mui/material/LinearProgress";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig/firebase";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

const MySwal = withReactContent(Swal);

function AgregarComunicado() {
  const cuponesList = collection(db, "CUPONES");
  const navigate = useNavigate();
  const imagen = "../img/";

  const [contador, setContador] = useState("0");
  const [categoria, setCategoria] = React.useState("");
  const [redSocial, setRedSocial] = React.useState("");
  const [mapa, setMapa] = React.useState("");
  const [drive, setDrive] = React.useState("");
  const [estado, setEstado] = React.useState("");
  const [tienda, setTienda] = useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [detalle, setDetalle] = React.useState("");
  const [cantidadCupones, setCantidadCupones] = useState("0");
  const [mensaje, setMensaje] = useState('');

  const [urlImagen, setUrlImage] = useState("");
  const [imagenLocal, setImagenLocal] = useState("imagen.png");
  const [pbVisible, setpbVisible] = useState(false);
  const handleChangeCat = (event) => {
    setCategoria(event.target.value);
  };
  const handleChangeEs = (event) => {
    setEstado(event.target.value);
  };
  const validarFormulario = (e) => {
    if (
      tienda == "" ||
      descripcion == "" ||
      detalle == "" ||
      estado == "" ||
      categoria == "" ||
      redSocial == "" ||
      mapa == "" ||
      drive == "" ||
      contador == "0"
    ) {
      MySwal.fire("", "Llenar todos los campos", "error");
    } else {
      if (urlImagen == "") {
        MySwal.fire("", "Subir imagen ", "error");
      } else {
        store(e);
      }
    }
  };
  const archivoHandler = (e) => {
    const archivo = e.target.files[0];
    setpbVisible(true);
    var today = new Date();
    var prefijoImagen =
      today.getDate().toString() +
      today.getHours().toString() +
      today.getMinutes().toString() +
      today.getSeconds().toString();

    const storageRef = ref(
      storage,
      "imagenesCupones/" + prefijoImagen + archivo.name
    );

    uploadBytes(storageRef, archivo).then((snapshot) => {
      setpbVisible(false);

      const storage = getStorage();
      getDownloadURL(
        ref(storage, "imagenesCupones/" + prefijoImagen + archivo.name)
      )
        .then((url) => {
          setUrlImage(url);
          console.log("URL " + url);
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            setpbVisible(false);

            const blob = xhr.response;
          };
          xhr.open("GET", url);
          xhr.send();
          // Or inserted into an <img> element
          const img = document.getElementById("imagenTienda");
          img.setAttribute("src", url);
        })
        .catch((error) => {
          console.log("error " + error);
        });
    });
  };
  //Enviar notificaciones
  const enviarSolicitud = (titulo,tienda) => {
    const url = "https://fcm.googleapis.com/fcm/send "; // Reemplaza con la URL de tu API
    // Datos que deseas enviar en el cuerpo de la solicitud POST
    const datos = {
      notification: {
        title: tienda,
        body: titulo,
        icon: "/src/img/comunicado.png",
      },
      to: "/topics/all",
    };
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Especifica el tipo de contenido en el cuerpo de la solicitud
        Authorization:
          "key=AAAANStXOFY:APA91bFoKpgbGz_GYpj-TaynD6fMBuDXkMoinnCbn5lmdyguxPut1phAicar8mxn4QEgT-4QvnGQew2reohSYpSIVVVr3mjrntB4UbTyBuf1ABpEt7r_wgkuCjU-yQSwyxyhsSenJ2Er",
      },
      body: JSON.stringify(datos), // Convierte el objeto de datos a formato JSON
    })
      .then((response) => response.json()) // Parsea la respuesta como JSON
      .then((data) => {
        // Aquí puedes manejar la respuesta del servidor
        setMensaje(data.mensaje); // Por ejemplo, mostrar un mensaje de éxito o error
      })
      .catch((error) => {
        // Aquí puedes manejar cualquier error ocurrido durante la solicitud
        console.error("Error:", error);
      });
  };
  const store = async (e) => {
    e.preventDefault();

    MySwal.fire({
      title: "Agregar cupón? ",
      text: "Este cupón estara visible para todos los usuarios",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Agregar",
    }).then(async (result) => {
      if (result.isConfirmed) {
		enviarSolicitud(descripcion,tienda)
        await addDoc(cuponesList, {
          tienda: tienda,
          descripcion: descripcion,
          detalle: detalle,
          categoria: categoria,
          redSocial: redSocial,
          estado: estado,
          urlImagen: urlImagen,
          cuponXusuario: contador,
          urlMapa: mapa,
          urlDrive: drive,
          cantidadCodigos: 0,
        });
        Swal.fire("Agregado", "El cupón ha sido agregado", "success");
        navigate("/panel/cupon");
      }
    });
  };
  //cerrar agregar cupon
  const cerrar = () => {
    navigate("../panel/cupon");
  };
  return (
    <Fragment>
      <NavBar
        titulo="Agregar cupón"
        imagen="cerrar"
        logoTitulo="cupon"
        cerrar={cerrar}
      ></NavBar>
      <div className="agregarCupon">
        <div className="grid-container">
          <div className="grid-item">
            <div className="divImagen">
              <div className="image-upload">
                <label htmlFor="file-input">
                  <img
                    id="imagenTienda"
                    src={require(`../img/${imagenLocal}`)}
                    alt="logo-titulo"
                    className="imagenEmpresa"
                  />
                </label>
                <input
                  className="btnAgrearImagenCupon"
                  id="file-input"
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  onChange={archivoHandler}
                />
              </div>
              {pbVisible ? (
                <LinearProgress color="success" style={{ marginBottom: 20 }} />
              ) : null}
            </div>
            <div className="formNumber">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 0, width: "300px" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Tienda"
                  variant="outlined"
                  value={tienda}
                  onChange={(e) => setTienda(e.target.value)}
                />
              </Box>
            </div>

            <div className="formNumber">
              <Box sx={{ width: 300 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Categoria
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoria}
                    label="Categoria"
                    onChange={handleChangeCat}
                  >
                    <MenuItem value={"Restaurantes"}>Restaurantes</MenuItem>
                    <MenuItem value={"Diversión"}>Diversión</MenuItem>
                    <MenuItem value={"Moda"}>Moda</MenuItem>
                    <MenuItem value={"Salud"}>Salud</MenuItem>
                    <MenuItem value={"Formación"}>Formación</MenuItem>
					<MenuItem value={"Suplementos"}>Suplementos</MenuItem>
                    <MenuItem value={"Otros"}>Otros</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="formNumber">
              <Box sx={{ width: 300 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={estado}
                    label="Estado"
                    onChange={handleChangeEs}
                  >
                    <MenuItem value={"Activo"}>Activo</MenuItem>
                    <MenuItem value={"Inactivo"}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className="formNumber">
              <label className="label" style={{ marginRight: "40px" }}>
                Cupones por persona
              </label>
              <IncrementadorNum
                style={{ margin: "30px" }}
                contador={contador}
                setContador={setContador}
              ></IncrementadorNum>
            </div>
          </div>
          <div className="grid-item grid-derecha">
            <form action="/" className="form">
              <label className="label">Descripción del cupón</label>
              <textarea
                type="text"
                id="descripcion"
                maxLength="200"
                placeholder="Máximo 200 caracteres."
                className="input input-descripcion"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
              <label className="label">Detalles del cupón </label>
              <textarea
                type="text"
                id="detalle"
                className="input input-detalles"
                placeholder="Máximo 1000 caracteres."
                maxLength="1000"
                value={detalle}
                onChange={(e) => setDetalle(e.target.value)}
              />
              <label className="label">Enlace red social</label>
              <input
                type="text"
                id="redSocial"
                value={redSocial}
                onChange={(e) => setRedSocial(e.target.value)}
                className="input input-titulo"
              />
              <label className="label">Enlace mapa</label>
              <input
                type="text"
                id="mapa"
                value={mapa}
                onChange={(e) => setMapa(e.target.value)}
                className="input input-titulo"
              />
              <label className="label">Enlace drive de promociones</label>
              <input
                type="text"
                id="drive"
                value={drive}
                onChange={(e) => setDrive(e.target.value)}
                className="input input-titulo"
              />
            </form>
            <div className="addCupon">
              <input
                type="submit"
                value="Agregar Cupón"
                onClick={validarFormulario}
                className="btnAgregarComunicado"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AgregarComunicado;
