import NavBar from "./componentes/NavBar";
import ItemPanel from "./componentes/ItemPanel";
import "./CSS/panel.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useState, useEffect } from "react";
import NotificationButton from "./NotificationButton";

const MySwal = withReactContent(Swal);

function Panel() {
  const navigate = useNavigate();

  const [nombre, setNombre] = useState("");
  const elementos = [
    { nombre: "Comunicados", imagen: "comunicado" },
    { nombre: "Cupones", imagen: "cupon" },
    { nombre: "Usuarios", imagen: "usuarios" },
    { nombre: "Notificaciones", imagen: "notificacion" },
  ];
  const agregarUsuarios = [{ nombre: "Usuarios", imagen: "usuarios" }];
  //cerrar cupones
  const cerrar = () => {
    MySwal.fire({
      title: "Cerrar sesión?",
      text: "Desea cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar sesión",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("idLocal", "");
        localStorage.setItem("nombreLocal", "");
        navigate("../login");
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("idLocal") == "") {
      const idLocal = localStorage.getItem("idLocal");
      console.log("NO ES USER");
      navigate("../login");
    } else {
      setNombre(localStorage.getItem("nombreLocal"));
    }
  }, []);
  return (
    <div className="panel">
      <NavBar
        titulo="BIENVENIDO"
        nombre={nombre}
        imagen="salir"
        logoTitulo="hola"
        cerrar={cerrar}
      ></NavBar>
      <div className="panel-descrip">
        <strong>PANEL DE CONTROL </strong>
        <NotificationButton></NotificationButton>
      </div>

      <div className="panel-grid-container">
        {localStorage.getItem("nivelUsuario") == "master"
          ? elementos.map((item) => (
              <div
                className="panel-element"
                onClick={() => navigate("./" + item.imagen)}
                key={item.nombre}
              >
                <ItemPanel nombre={item.nombre} imagen={item.imagen} />
              </div>
            ))
          : agregarUsuarios.map((item) => (
              <div
                className="panel-element"
                onClick={() => navigate("./" + item.imagen)}
                key={item.nombre}
              >
                <ItemPanel nombre={item.nombre} imagen={item.imagen} />
              </div>
            ))}
      </div>
    </div>
  );
}

export default Panel;
