import logo from "../img/logo.png";
import hola from "../img/hola.png";
import "../CSS/navbar.css";

function NavBar(props) {
  return (
    <nav>
      <div className="navbar-left">
        <img src={logo} alt="logo" className="logo-navbar" />
      </div>
      <div className="navbar-center">
        <img
          src={require(`../img/${props.logoTitulo}.png`)}
          alt="logo-titulo"
          className="hola"
        />
        <h1 className="titulo">{props.titulo} </h1>
        <h1 className="name">{props.nombre}</h1>
      </div>
      <div className="navbar-right" type="submit">
        <img
          src={require(`../img/${props.imagen}.png`)}
          type="submit"
          alt="salir"
          className="salida"
          onClick={() => props.cerrar()}

        />
      </div>
    </nav>
  );
}

export default NavBar;
