import React, { useState, useEffect } from "react";
import NavBar from "./componentes/NavBar";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "./FirebaseConfig/firebase";
import ItemComunicadoMejor from "./componentes/ItemComunicadoMejor";
import styles from "./CSS/comunicados.module.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";

const MySwal = withReactContent(Swal);
function ComunicadosTrabajador() {
  //configurar useState
  const [comunicados, setComunicados] = useState([]);
  const [datosCargados, setDatosCargados] = useState(true); // MOSTRAR BARRA DE CARGA
  const [flotante, setFlotante] = useState(false); // MOSTRAR BARRA DE CARGA

  //referenciar la DB
  const comunicadosList = collection(db, "COMUNICADOS");

  //funcion para mostrar la lista de comunicados
  const getComunicados = async () => {
    const data = await getDocs(comunicadosList);
    const sortedData = data.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .sort((a, b) => b.fecha - a.fecha); // Orden descendente basado en el campo "fecha"

    setComunicados(sortedData);
  };
  //Funcion para eliminar comunicados
  const deleteComunicado = async (id) => {
    const comunicado = doc(db, "COMUNICADOS", id);
    await deleteDoc(comunicado);
    getComunicados();
  };
  const handleImageLoad = () => {
    //console.log("a ver ");
    setDatosCargados(true);
  };
  //Confifuracion Sweet alert
  const confirmDelete = (id) => {
    MySwal.fire({
      title: "Eliminar comunicado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar! ",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteComunicado(id);
        Swal.fire("Eliminado!", "El comunicado ha sido eliminado", "success");
      }
    });
  };
  const navigate = useNavigate();
  //cerrar comunicados
  const cerrar = () => {
    if (localStorage.getItem("nivelUsuario") == "0") {
      navigate("../main");
    }
    if (localStorage.getItem("nivelUsuario") == "admin") {
      navigate("../panel");
    }
	if (localStorage.getItem("nivelUsuario") == "master") {
		navigate("../panel");
	  }
  };
  //cerrar comunicados
  const abrirFormulario = () => {
    navigate("../panel");
  };
  //uso de useEffect
  useEffect(() => {
    getComunicados();
    if (localStorage.getItem("nivelUsuario") == "0") {
      setFlotante(false);
    }
    if (localStorage.getItem("nivelUsuario") == "admin") {
      setFlotante(true);
    }
    if (localStorage.getItem("nivelUsuario") == "master") {
      setFlotante(true);
    }
  }, []);
  useEffect(() => {
    //console.log(comunicados[0].fecha.toMillis());
  }, [comunicados]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px auto",
          textAlign: "center",
          height: !datosCargados ? "100vh" : "0px",
          justifyContent: "center",
          alignItems: "center",
          visibility: !datosCargados ? "visible" : "hidden",
        }}
      >
        <div style={{ verticalAlign: "middle", display: "block" }}>
          <div>
            <img
              style={{ height: "100px" }}
              src={require(`./img/globo.gif`)}
              alt="esperando"
            />
          </div>
        </div>
      </div>
      <div
        className={styles.comunicados}
        style={{
          visibility: datosCargados ? "visible" : "hidden",
        }}
      >
        <NavBar
          titulo="Comunicados"
          imagen="cerrar"
          logoTitulo="comunicado"
          cerrar={cerrar}
        ></NavBar>

        <div className={styles.gridcontainer}>
          {comunicados.map((item) => (
            <div className={styles.element} key={item.id}>
              <ItemComunicadoMejor
                titulo={item.titulo}
                descripcion={item.descripcion}
                fecha={item.fecha.toMillis()}
                id={item.id}
                urlImagen={item.urlImagen}
                deleteComunicado={confirmDelete}
                handleImageLoad={handleImageLoad}
              ></ItemComunicadoMejor>
            </div>
          ))}
        </div>
        {flotante ? (
          <Link
            className={styles.flotante}
            to={`/panel/comunicado/agregarcomunicado`}
          >
            <img
              src={require(`./img/plus.png`)}
              className={styles.boton}
              border="0"
            />
          </Link>
        ) : null}
      </div>{" "}
    </div>
  );
}

export default ComunicadosTrabajador;
