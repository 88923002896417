import logo from "./img/logo_nombre.png";
import "./CSS/login.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "./FirebaseConfig/firebase";
import Alert from "@mui/material/Alert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function Login() {
  const navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState({});

  const [loginDetails, setLoginDetails] = useState({
    dni: "",
    password: "",
    name: "",
  });

  const saveData = (idLocal, nombreLocal, nivelUsuario) => {
    localStorage.setItem("idLocal", idLocal);
    localStorage.setItem("nombreLocal", nombreLocal);
    localStorage.setItem("nivelUsuario", nivelUsuario);
  };

  //Verificar si esta activo o no
  const verificarFecha = (fechaStr, meses) => {
    console.log(fechaStr);
    const [dia, mes, año] = fechaStr.split("/");
    // Crear un nuevo objeto Date
    const fechaOriginal = new Date(`${año}/${mes}/${dia}`);

    const fechaActual = new Date();
    console.log("fecha original");
    console.log(fechaOriginal);
    console.log("fecha actual");
    console.log(fechaActual);
    const nuevaFecha = new Date(
      fechaOriginal.setMonth(fechaOriginal.getMonth() + meses)
    );
    return nuevaFecha <= fechaActual;
  };

  async function getUsersByDni(loginDetails) {
    if (!loginDetails) {
      return;
    }
    let fl = 0;
    const q = query(
      collection(db, "USUARIOS"),
      where("dni", "==", loginDetails.dni)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      fl = 1;
      if (doc.data().nroRegistro == loginDetails.password) {
        console.log("INGRESO", doc.data());
        if (doc.data().nivel == "0") {
          //verificar si es usuario activo.
          if (verificarFecha(doc.data().fechaInicio, doc.data().meses)) {
            console.log("NO PUEDE");
            Swal.fire(
              "ACCESO DENEGADO",
              "Tu subscripción ha vencido. Envia un mensaje con tu DNI al numero 9XXXXXXXX en caso sea un error.",
              "error"
            );
          } else {
            saveData(doc.data().dni, doc.data().nombre, doc.data().nivel);
            setLoginDetails({ name: doc.data().nombre });
            navigate("../main");
          }
        } else {
          saveData(doc.data().dni, doc.data().nombre, doc.data().nivel);

          if (doc.data().nivel == "1") navigate("../buscadorCodigos");
          if (doc.data().nivel == "admin") navigate("../panel");
          if (doc.data().nivel == "master") navigate("../panel");
        }
      } else {
        console.log("contraseña incorrecta");
        setErrorMessages({ name: "pass", message: "Contraseña incorrecta" });
      }
    });
    if (fl == 0) {
      console.log("Usuario no encontrado");
      setErrorMessages({ name: "pass", message: "Usuario incorrecto" });
    }
  }
  const handleLogin = (loginDetails) => {
    console.log("user" + loginDetails.dni + " pass " + loginDetails.password);
    getUsersByDni(loginDetails);
  };

  const handleInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <Alert variant="outlined" severity="error">
        {errorMessages.message}
      </Alert>
    );

  const forgotPass = () => {
    Swal.fire(
      "Olvidaste tu contraseña?",
      "Envia un mensaje con tu DNI al numero 9XXXXXXXX y resetaremos tu contraseña",
      "question"
    );
  };
  const aver = (e) => {
    handleLogin(loginDetails);
    e.preventDefault();
  };
  useEffect(() => {
    getUsersByDni();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("idLocal") == "") {
      console.log("NO ES USER");
      navigate("../login");
    } else {
      const nivelUsuario = localStorage.getItem("nivelUsuario");
      if (nivelUsuario == "0") navigate("../main");
      if (nivelUsuario == "1") navigate("../buscadorCodigos");
      if (nivelUsuario == "2") navigate("../login");
    }
  }, []);
  return (
    <div className="login">
      <div className="login-root">
        <div className="login-padreIzquierda">
          <div className="login-hIzquierda">
            <img src={logo} className="login-logoLogin" />
          </div>
        </div>
        <div className="login-padreDerecha">
          <div className="login-hDerecha">
            <h1 className="login-title">Iniciar sesion</h1>
            <form action="/" className="login-form">
              <label className="login-label">DNI</label>
              <input
                type="text"
                name="dni"
                value={loginDetails.dni}
                onChange={handleInputChange}
                className="login-input login-input-email"
              />
              <label className="login-label">Contraseña</label>
              <input
                type="password"
                name="password"
                placeholder="*********"
                value={loginDetails.password}
                onChange={handleInputChange}
                className="login-input login-input-password"
              />
              {renderErrorMessage("pass")}
              <input
                type="submit"
                value="Ingresar"
                onClick={aver}
                className="login-primary-button login-login-button"
              />
              <a className="contra" onClick={forgotPass}>
                {" "}
                Olvidé mi contraseña
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
