import "../CSS/itemCodigoCupon.css";
import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";

function ItemCodigoCupon(props) {
  const [sexo, setSexo] = useState("trabajador");
  const [estado, setEstado] = useState("#E1F4B2");

  useEffect(() => {
    if (props.sexo == "Mujer") setSexo("trabajadora");
    if (props.estado == "Inactivo") setEstado("#FF8D8D");
  }, []);
  return (
    <Fragment>
      <div className="cupon-card">
        <div className="user-infocard">
          <img
            id="imagenTienda"
            className="user-icon"
            src={require(`../img/${sexo}.png`)}
            alt="flecha"
          />
          <div className="user-informacion">
            <div>
              <h5 className="user-nombre">{props.nombre}</h5>
              <small>{props.dni}</small>
            </div>
          </div>
        </div>
      </div>
      <div className="cupon-card">
        <div>
          <img
            id="imagenTienda"
            className="cupon-logoEmpresa"
            src={props.imagen}
            alt="imagen"
          />
        </div>
        <div className="itemBody">
          <div className="cupon-cabecera">
            <p>{props.tienda}</p>
            <p>{props.descripcion}</p>
          </div>
        </div>
      </div>
      <div className="cupon-card" style={{ display: "block" }}>
        <div className="cupon-cabecera" style={{ margin: "20px" }}>
          <p>{props.tienda}</p>
          <p>{props.detalle}</p>
        </div>
      </div>
    </Fragment>
  );
}

export default ItemCodigoCupon;
