import estilos from "../CSS/itemComunicadoMejor.module.css";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseConfig/firebase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { Fragment, useEffect, useState } from "react";

function ItemComunicadoMejor(props) {
  const navigate = useNavigate();
  const [cerrarVisible, setCerrar] = useState(true);

  // Redirige al usuario a otro sitio
  const handleDivClick = () => {
    navigate(`/panel/comunicado/${props.id}`);
  };
  const timestampBaseDatos = props.fecha;

  //calcular hace cuantos dias
  const calcularDiferenciaTiempo = () => {
    const ahora = moment(); // Obtiene la fecha y hora actual
    const fechaBase = moment(timestampBaseDatos); // Convierte el timestamp de la base de datos a un objeto Moment

    const diferenciaMinutos = ahora.diff(fechaBase, "minutes"); // Calcula la diferencia en minutos
    const diferenciaHoras = ahora.diff(fechaBase, "hours"); // Calcula la diferencia en horas
    const diferenciaDias = ahora.diff(fechaBase, "days"); // Calcula la diferencia en días

    if (diferenciaMinutos < 60) {
      return `${diferenciaMinutos} minutos`;
    } else if (diferenciaHoras < 24) {
      return `${diferenciaHoras} hora(s)`;
    } else {
      return `${diferenciaDias} día(s)`;
    }
  };
  useEffect(() => {

    if (localStorage.getItem("nivelUsuario") == "0") {
      setCerrar(false)
    } else {
      //navigate("../panel/comunicado");
    }
  }, []);
  return (
    <div className={estilos.card} >
      <div className={estilos.imagen} onClick={handleDivClick}>
        <img
          id="imagenTienda"
          src={props.urlImagen}
          alt="flecha"
		  onLoad={props.handleImageLoad}
        />
      </div>
      <div className={estilos.itemBody} >
        <div className={estilos.cabecera} onClick={handleDivClick}>
          <p>{props.titulo}</p>
          <div style={{ display: "flex" }}>
            <AccessTimeIcon style={{ marginRight: "6px" }}></AccessTimeIcon>
            <p style={{ margin: "0px" }}>Hace {calcularDiferenciaTiempo()}</p>
          </div>
        </div>
        {cerrarVisible ? (
          <img
            onClick={() => props.deleteComunicado(props.id)}
            className={estilos.icons}
            src={require(`../img/borrar.png`)}
            alt=""
          />
        ) : null}
      </div>
    </div>
  );
}

export default ItemComunicadoMejor;
