import "../CSS/formAgregarCupon.css";
import IncrementadorNum from "./IncrementadorNum";
import React, { useRef, useEffect, useState, Fragment } from "react";
import NavBar from "./NavBar";
import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LinearProgress from "@mui/material/LinearProgress";
import { collection, addDoc, getDoc, setDoc, doc } from "firebase/firestore";
import { db } from "../FirebaseConfig/firebase";
import { useNavigate, useParams, Link } from "react-router-dom";
import { TextField } from "@mui/material";

const MySwal = withReactContent(Swal);

function FormEditarCupon() {
  const cuponesList = collection(db, "CUPONES");

  const navigate = useNavigate();
  const { id } = useParams();

  //Funcion para mostrar detalles del comunicados
  const getCuponById = async (id) => {
    const cupon = await getDoc(doc(db, "CUPONES", id));
    if (cupon) {
      setTienda(cupon.data().tienda);
      setDescripcion(cupon.data().descripcion);
      setDetalle(cupon.data().detalle);
      setContador(cupon.data().cuponXusuario);
      setUrlImage(cupon.data().urlImagen);
      setCategoria(cupon.data().categoria);
      setEstado(cupon.data().estado);
      setCantidadCupones(cupon.data().cantidadCodigos);
      setRedSocial(cupon.data().redSocial);
      if (cupon.data().urlMapa != undefined) setMapa(cupon.data().urlMapa);
      if (cupon.data().urlDrive != undefined) setDrive(cupon.data().urlDrive);

      console.log(cupon.data());
    } else {
      console.log("no existe");
    }
  };

  const [contador, setContador] = useState("0");
  const [categoria, setCategoria] = React.useState("");
  const [estado, setEstado] = React.useState("");
  const [tienda, setTienda] = useState("");
  const [redSocial, setRedSocial] = useState("");
  const [mapa, setMapa] = useState("");
  const [drive, setDrive] = useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [detalle, setDetalle] = React.useState("");
  const [cantidadCodigos, setCantidadCupones] = useState("0");

  const [urlImagen, setUrlImage] = useState("");
  const [imagenLocal, setImagenLocal] = useState("imagen.png");
  const [pbVisible, setpbVisible] = useState(false);

  const handleChangeCat = (event) => {
    setCategoria(event.target.value);
  };
  const handleChangeEs = (event) => {
    setEstado(event.target.value);
  };

  const archivoHandler = (e) => {
    const archivo = e.target.files[0];
    setpbVisible(true);
    var today = new Date();
    var prefijoImagen =
      today.getDate().toString() +
      today.getHours().toString() +
      today.getMinutes().toString() +
      today.getSeconds().toString();

    const storageRef = ref(storage, prefijoImagen + archivo.name);

    uploadBytes(storageRef, archivo).then((snapshot) => {
      setpbVisible(false);

      const storage = getStorage();
      getDownloadURL(ref(storage, prefijoImagen + archivo.name))
        .then((url) => {
          setUrlImage(url);
          console.log("URL " + url);
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            setpbVisible(false);

            const blob = xhr.response;
          };
          xhr.open("GET", url);
          xhr.send();
          // Or inserted into an <img> element
          const img = document.getElementById("imagenTienda");
          img.setAttribute("src", url);
        })
        .catch((error) => {
          console.log("error " + error);
        });
    });
  };
  const confirmEdit = (e) => {
    MySwal.fire({
      title: "Editar cupón? ",
      text: "Este cupón estara visible para todos los trabajadores",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Editar",
    }).then((result) => {
      if (result.isConfirmed) {
        update(e);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Cupón editado",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/panel/cupon");
      }
    });
  };
  const update = async (e) => {
    e.preventDefault();
    console.log(id);
    const cupon = doc(db, "CUPONES", id);
    const data = {
      tienda: tienda,
      descripcion: descripcion,
      detalle: detalle,
      categoria: categoria,
      estado: estado,
      redSocial: redSocial,
      urlImagen: urlImagen,
      cuponXusuario: contador,
      cantidadCodigos: cantidadCodigos,
      urlMapa: mapa,
      urlDrive: drive,
    };
    await setDoc(cupon, data);
  };
  //cerrar editar Cupon
  const cerrar = () => {
    navigate("../panel/cupon");
  };
  useEffect(() => {
    getCuponById(id);
  }, []);
  return (
    <Fragment>
      <NavBar
        titulo="Detalles del cupón"
        imagen="cerrar"
        logoTitulo="cupon"
        cerrar={cerrar}
      ></NavBar>
      <div className="agregarCupon">
        <div className="grid-container">
          <div className="grid-item">
            <div className="divImagen">
              <div className="image-upload">
                <label htmlFor="file-input">
                  <img
                    id="imagenTienda"
                    src={urlImagen}
                    alt="logo-titulo"
                    className="imagenEmpresa"
                    style={{ width: "100%" }}
                  />
                </label>
                <input
                  className="btnAgrearImagenCupon"
                  id="file-input"
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  onChange={archivoHandler}
                />
              </div>
              {pbVisible ? (
                <LinearProgress color="success" style={{ marginBottom: 20 }} />
              ) : null}
            </div>
            <div className="formNumber">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 0, width: "300px" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Tienda"
                  variant="outlined"
                  value={tienda}
                  onChange={(e) => setTienda(e.target.value)}
                />
              </Box>
            </div>
            <div className="formNumber">
              
              <Box sx={{ m: 0, width: 300 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoria}
                    label="Estado"
					onChange={(e) => setCategoria(e.target.value)}
					>
                    <MenuItem value={"Restaurantes"}>Restaurantes</MenuItem>
                    <MenuItem value={"Diversión"}>Diversión</MenuItem>
                    <MenuItem value={"Moda"}>Moda</MenuItem>
                    <MenuItem value={"Salud"}>Salud</MenuItem>
                    <MenuItem value={"Formación"}>Formación</MenuItem>
					<MenuItem value={"Suplementos"}>Suplementos</MenuItem>
                    <MenuItem value={"Otros"}>Otros</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="formNumber">
              <Box sx={{ m: 0, width: 300 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={estado}
                    label="Estado"
                    onChange={handleChangeEs}
                  >
                    <MenuItem value={"Activo"}>Activo</MenuItem>
                    <MenuItem value={"Inactivo"}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className="formNumber">
              <label className="label" style={{ marginRight: "40px" }}>
                Cupones por persona
              </label>
              <IncrementadorNum
                contador={contador}
                setContador={setContador}
              ></IncrementadorNum>
            </div>
          </div>
          <div className="grid-item grid-derecha">
            <form action="/" className="form">
              <label className="label">Descripción del cupón</label>
              <textarea
                type="text"
                id="descripcion"
                maxLength="200"
                placeholder="Máximo 200 caracteres."
                className="input input-descripcion"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
              <label className="label">Detalles del cupón </label>
              <textarea
                type="text"
                id="detalle"
                className="input input-detalles"
                placeholder="Máximo 1000 caracteres."
                maxLength="1000"
                value={detalle}
                onChange={(e) => setDetalle(e.target.value)}
              />
              <label className="label">Enlace red social</label>
              <input
                type="text"
                id="redSocial"
                value={redSocial}
                onChange={(e) => setRedSocial(e.target.value)}
                className="input input-titulo"
              />
              <label className="label">Enlace mapa</label>
              <input
                type="text"
                id="mapa"
                value={mapa}
                onChange={(e) => setMapa(e.target.value)}
                className="input input-titulo"
              />
              <label className="label">Enlace drive de promociones</label>
              <input
                type="text"
                id="drive"
                value={drive}
                onChange={(e) => setDrive(e.target.value)}
                className="input input-titulo"
              />
            </form>
            <div style={{ textAlign: "center" }}>
              <div className="addCupon">
                <input
                  type="submit"
                  value="Editar Cupón"
                  onClick={confirmEdit}
                  className="btnAgregarComunicado"
                />
              </div>
            </div>
            <Link
              to={`/panel/cupon/reporte/${id}`}
              className="label"
              style={{ textAlign: "center", marginTop: "18px" }}
            >
              <div className="addCupon">
                <input
                  type="submit"
                  value="Ver Reporte"
                  className="btnAgregarComunicado"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FormEditarCupon;
