import React, { useState, useEffect, Component } from "react";
import NavBar from "./componentes/NavBar";
//import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "./FirebaseConfig/firebase";
import { orderBy, limit } from "firebase/firestore";
import ItemPanelUsuario from "./componentes/ItemPanelUsuario";
import styles from "./CSS/mainTrabajador.module.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";
import ItemCuponTrabajador from "./componentes/ItemCuponTrabajador";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NotificationButton from "./NotificationButton";
import svgImage from './img/diversion.svg';

const MySwal = withReactContent(Swal);
function Comunicados() {
  //configurar useState
  const [comunicados, setComunicados] = useState([]);
  const [cupones, setCupones] = useState([]);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");

  const [datosCargados, setDatosCargados] = useState(false); // MOSTRAR BARRA DE CARGA

  //referenciar la DB
  const comunicadosList = collection(db, "COMUNICADOS");
  const cuponesList = collection(db, "CUPONES");

  //lista de categorias
  const elementos = [
    { nombre: "Diversión", imagen: "diversion" },
    { nombre: "Salud", imagen: "salud" },
    { nombre: "Formación", imagen: "formacion" },
    { nombre: "Restaurantes", imagen: "restaurantes" },
    { nombre: "Moda", imagen: "moda" },
	{ nombre: "Suplementos", imagen: "suplementos" },
	{ nombre: "Otros", imagen: "otros" },

  ];
  //ocultar gif de carga
  const handleImageLoad = () => {
    const timer = setTimeout(() => {
      setDatosCargados(true); // Cambiar el estado para mostrar el contenido después de 500ms
    }, 500);

    return () => clearTimeout(timer);
  };

  //funcion para mostrar la lista de comunicados
  const getComunicados = async () => {
    let articlesSnapshot = await getDocs(
      query(comunicadosList, orderBy("fecha", "desc"))
    );
    //const data = await getDocs(comunicadosList,orderBy('fecha'));
    setComunicados(
      articlesSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    //console.log(comunicados);
    //setDatosCargados(true);
  };

  //Funcion para mostrar cupones del usuario
  const getCuponesByEstado = async () => {
    const q = query(collection(db, "CUPONES"), where("estado", "==", "Activo"));
    const querySnapshot = await getDocs(q);
    setCupones(
      querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  //funcion para mostrar la lista de cupones
  const getCupones = async () => {
    const data = await getDocs(cuponesList);
    setCupones(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log(cupones);
  };

  const navigate = useNavigate();
  //cerrar sesion
  const salir = () => {
    MySwal.fire({
      title: "Cerrar sesión?",
      text: "Desea cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar sesión",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("idLocal", "");
        localStorage.setItem("nombreLocal", "");
        navigate("../login");
      }
    });
  };
  //cerrar comunicados
  const abrirFormulario = () => {
    navigate("../panel");
  };
  //uso de useEffect
  useEffect(() => {
    getComunicados();
    getCuponesByEstado();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("idLocal") == "") {
      const idLocal = localStorage.getItem("idLocal");
      console.log("NO ES USER");
      navigate("../login");
    } else {
      setNombre(localStorage.getItem("nombreLocal"));
	  //setApellido(localStorage.getItem("apellidoLocal"));
    }
    getComunicados();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px auto",
          textAlign: "center",
          height: !datosCargados ? "100vh" : "0px",
          justifyContent: "center",
          alignItems: "center",
          visibility: !datosCargados ? "visible" : "hidden",
        }}
      >
        <div style={{ verticalAlign: "middle", display: "block" }}>
          <div>
            <img
              style={{ height: "50px" }}
              src={require(`./img/vale.gif`)}
              alt="esperando"
            />
          </div>
        </div>
      </div>

      <div
        className={styles.comunicados}
        style={{
          visibility: datosCargados ? "visible" : "hidden",
		  display: datosCargados? "block" : "none",
        }}
      >
        <NavBar
          titulo="BIENVENIDO"
          nombre={nombre + apellido}
          imagen="salir"
          logoTitulo="hola"
          cerrar={salir}
        ></NavBar>
        <div className={styles.carousel}>
          <Carousel
            showThumbs={false}
            showStatus={true}
            infiniteLoop={false}
            autoPlay={true}
          >
            {comunicados.map((item, index) => (
              <div
                className={styles.comu}
                key={index}
                onClick={() => {
                  navigate("/main/comunicados");
                }}
              >
                <img
                  src={item.urlImagen}
                  alt="comunicado"
                  onLoad={handleImageLoad}
                />
              </div>
            ))}
          </Carousel>
        </div>

        <h1 className={styles.tituloComunicado}>BENEFICIOS</h1>
        <NotificationButton></NotificationButton>

        <div className={styles.gridContainer}>
          {elementos.map((item) => (
            <div
              className="panel-element"
              onClick={() => navigate("./" + item.nombre)}
            >
              <ItemPanelUsuario nombre={item.nombre} imagen={item.imagen} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Comunicados;
