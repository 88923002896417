import "../CSS/formAgregarComunicado.css";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig/firebase";
import { storage } from "../FirebaseConfig/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import IncrementadorNum from "./IncrementadorNum";

import { async } from "@firebase/util";
import NavBar from "./NavBar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LinearProgress from "@mui/material/LinearProgress";
import QuillEditor from "../componentes/QuillEditor";
const MySwal = withReactContent(Swal);

function FormAgregarComunicado() {
  const navigate = useNavigate();

  //Configurar hooks
  const [titulo, setTitulo] = useState("");
  const [detalle, setDetalle] = useState("");
  const [archivo, setArchivo] = useState("");
  const [imagenLocal, setImagenLocal] = useState("imagen.png");
  const [urlImagen, setUrlImage] = useState("");
  const [mensaje, setMensaje] = useState('');

  //hooks de visibilidad
  const [pbVisibleImage, setpbVisibleImagen] = useState(false);
  const [pbVisible, setpbVisible] = useState(false);
  const [archivoVisible, setarchivoVisible] = useState(false);

  const comunicadosList = collection(db, "COMUNICADOS");
  //Ocultar linea de progreso
  const handleImageLoad = () => {
    setpbVisibleImagen(false);
  };
  //Enviar notificaciones
  const enviarSolicitud = (titulo) => {
    const url = "https://fcm.googleapis.com/fcm/send "; // Reemplaza con la URL de tu API
    // Datos que deseas enviar en el cuerpo de la solicitud POST
    const datos = {
      notification: {
        title: "VC PROMOCION",
        body: titulo,
        icon: "../img/comunicado.png",
      },
      to: "/topics/all",
    };
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Especifica el tipo de contenido en el cuerpo de la solicitud
        Authorization:
          "key=AAAANStXOFY:APA91bFoKpgbGz_GYpj-TaynD6fMBuDXkMoinnCbn5lmdyguxPut1phAicar8mxn4QEgT-4QvnGQew2reohSYpSIVVVr3mjrntB4UbTyBuf1ABpEt7r_wgkuCjU-yQSwyxyhsSenJ2Er",
      },
      body: JSON.stringify(datos), // Convierte el objeto de datos a formato JSON
    })
      .then((response) => response.json()) // Parsea la respuesta como JSON
      .then((data) => {
        // Aquí puedes manejar la respuesta del servidor
        setMensaje(data.mensaje); // Por ejemplo, mostrar un mensaje de éxito o error
      })
      .catch((error) => {
        // Aquí puedes manejar cualquier error ocurrido durante la solicitud
        console.error("Error:", error);
      });
  };

  const validarFormulario = (e) => {
    if (titulo == "" || detalle == "" || urlImagen == "") {
      MySwal.fire("", "Llenar todos los campos", "error");
    } else {
      store(e);
    }
  };
  const store = async (e) => {
    MySwal.fire({
      title: "Agregar comunicado? ",
      text: "Este comunicado estara visible para todos los usuarios",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Agregar",
    }).then(async (result) => {
      if (result.isConfirmed) {
		enviarSolicitud(titulo)
        await addDoc(comunicadosList, {
          titulo: titulo,
          detalle: detalle,
          urlImagen: urlImagen,
          archivo: archivo,
          fecha: new Date(),
        });
        Swal.fire("Agregado", "El comunicado ha sido agregado", "success");
        navigate("/panel/comunicado");
      }
    });
  };
  //Subir archivo
  const archivoHandler = async (e) => {
    const archivo = e.target.files[0];
    console.log("asdasdasd");
    setArchivo(archivo.name);
    setpbVisible(true);
    const storageRef = ref(storage, "archivosComunicados/" + archivo.name);
    uploadBytes(storageRef, archivo).then((snapshot) => {
      MySwal.fire({
        position: "center",
        icon: "success",
        title: "Archivo subido con exito",
        showConfirmButton: false,
        timer: 1500,
      });
      setpbVisible(false);
      setarchivoVisible(true);
    });
  };
  //subir imagen
  const subirImagen = async (e) => {
    const archivo = e.target.files[0];
    setpbVisibleImagen(true);
    var today = new Date();
    var prefijoImagen =
      today.getDate().toString() +
      today.getHours().toString() +
      today.getMinutes().toString() +
      today.getSeconds().toString();

    const storageRef = ref(
      storage,
      "imagenesCabeceraComunicado/" + prefijoImagen + archivo.name
    );

    uploadBytes(storageRef, archivo).then((snapshot) => {
      const storage = getStorage();
      getDownloadURL(
        ref(
          storage,
          "imagenesCabeceraComunicado/" + prefijoImagen + archivo.name
        )
      )
        .then((url) => {
          setUrlImage(url);
          console.log("URL " + url);
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            const blob = xhr.response;
          };
          xhr.open("GET", url);
          xhr.send();
          // Or inserted into an <img> element
          const img = document.getElementById("imagenTienda");
          img.setAttribute("src", url);
        })
        .catch((error) => {
          console.log("error " + error);
        });
    });
  };
  //cerrar cupones
  const cerrar = () => {
    navigate("/panel/comunicado");
  };
  return (
    <Fragment>
      <NavBar
        titulo="Agregar Comunicado"
        nombre={""}
        imagen="cerrar"
        logoTitulo="comunicado"
        cerrar={cerrar}
      ></NavBar>
      <div className="agregarComunicado">
        <div className="fac-grid-container">
          <div className="fac-grid-item">
            <div className="fac-divImagen">
              <div className="fac-image-upload">
                <label htmlFor="file-input">
                  <img
                    id="imagenTienda"
                    src={require(`../img/${imagenLocal}`)}
                    alt="logo-titulo"
                    className="fac-imagenEmpresa"
                    onLoad={handleImageLoad}
                  />
                </label>
                <input
                  className="btnAgrearImagenCupon"
                  id="file-input"
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  onChange={subirImagen}
                />
              </div>
              {pbVisibleImage ? (
                <LinearProgress color="success" style={{ marginBottom: 20 }} />
              ) : null}
            </div>
            <form action="/" className="fac-form">
              <label className="fac-label">Titulo</label>
              <input
                type="text"
                id="titulo"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className="fac-input fac-input-titulo"
              />

              <label className="fac-label">Detalles</label>
              <QuillEditor content={detalle} setContent={setDetalle} />
            </form>
          </div>
          <div className="fac-grid-item">
            {archivoVisible ? (
              <div
                className="fac-panel-element"
                style={{
                  display: "flex",
                  marginBottom: 20,
                  alignItems: "center",
                }}
              >
                <img
                  src={require(`../img/archivo.png`)}
                  type="submit"
                  alt="salir"
                  className="fac-salida"
                />
                {archivo}
              </div>
            ) : null}
            {pbVisible ? (
              <LinearProgress color="success" style={{ marginBottom: 20 }} />
            ) : null}

            <div>
              <input
                type="file"
                className="fac-btnAgregarDocumento"
                onChange={archivoHandler}
              />
            </div>
            <div>
              <input
                type="submit"
                onClick={validarFormulario}
                value="Agregar Comunicado"
                className="fac-btnAgregarComunicado"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FormAgregarComunicado;
