import { initializeApp } from "firebase/app";

import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
	apiKey: "AIzaSyDDXJJkYaZ6sb8sbHU_Ich3ynK7CNB81iA",
	authDomain: "imperium-app-3fe34.firebaseapp.com",
	projectId: "imperium-app-3fe34",
	storageBucket: "imperium-app-3fe34.appspot.com",
	messagingSenderId: "228360403030",
	appId: "1:228360403030:web:a43d8ce07208a4e37e63f7",
	measurementId: "G-V2RGFQQ7VS"
  };
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
